<template>
    <AppTabLinks :links="list" />
</template>

<script>
    import { mapState } from 'vuex';
    import AppTabLinks from '@/components/AppTabLinks'

    export default {
        components: {
            AppTabLinks
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', ['entity']),
            isAdmin() {
                return this.user.admin;
            },
            list() {
                return [
                    {
                        title: 'Карточка',
                        url: `/sites/${this.$route.params.id}/detail`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Редактировать',
                        url: this.$route.params.id ? `/sites/${this.$route.params.id}/edit` : '/sites/edit'
                    },
                    {
                        title: 'Настройки сайта',
                        url: `/sites/${this.$route.params.id}/setting`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Стили сайта',
                        url: `/sites/${this.$route.params.id}/style`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Расписание работы',
                        url: `/sites/${this.$route.params.id}/schedules`,
                        disabled: !this.$route.params.id,
                        fill_required: !(this.entity.schedules && this.entity.schedules.length)
                    },
                    {
                        title: 'Категории',
                        url: `/sites/${this.$route.params.id}/categories`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Блюда',
                        url: `/sites/${this.$route.params.id}/products`,
                        disabled: !this.$route.params.id
                    },
                    {
                        title: 'Точки продаж',
                        url: `/sites/${this.$route.params.id}/entities`,
                        disabled: !this.$route.params.id
                    }
                ]
            }
        },
        mounted() {
            // if(this.isAdmin) {
            //     this.list = [
            //         {
            //             title: 'Карточка',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}`
            //         },
            //         {
            //             title: 'Редактировать',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/edit`
            //         },
            //         {
            //             title: 'Описание',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/description`
            //         },
            //         {
            //             title: 'Категории',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/categories`
            //         },
            //         {
            //             title: 'Блюда',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/products`
            //         },
            //         {
            //             title: 'Опции',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/options`
            //         },
            //         {
            //             title: 'Варианты',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/variants`
            //         },
            //         {
            //             title: 'Импорт',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/import`
            //         },
            //         {
            //             title: 'Акции',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/promotions`
            //         },
            //         {
            //             title: 'Дополнительно',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/additional`
            //         },
            //         {
            //             title: 'Словарь',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/dictionary`
            //         },
            //         {
            //             title: 'Доставка',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/delivery`
            //         },
            //         {
            //             title: 'Перенаправление доставки',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/delivery-address-redirect`
            //         },
            //         {
            //             title: 'Настройки',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/settings`
            //         },
            //     ]
            // } else {
            //     this.list = [
            //         {
            //             title: 'Карточка',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}`
            //         },
            //         {
            //             title: 'Описание',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/description`
            //         },
            //         {
            //             title: 'Категории',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/categories`
            //         },
            //         {
            //             title: 'Блюда',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/products`
            //         },
            //         {
            //             title: 'Опции',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/options`
            //         },
            //         {
            //             title: 'Варианты',
            //             url: `/sites/${this.$route.params.code}/${this.$route.params.id}/variants`
            //         },
            //     ]
            // }
        },
    }
</script>
