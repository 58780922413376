<template>
    <v-row>
        <v-col>
            <v-card class="pa-4">
                <v-card-title>{{ entity.title }}</v-card-title>
                <v-card-text>
                    <SiteTabLinks />
                    <v-row class="pl-3 pb-3" />
                    <v-row form class="form-group">
                        <v-col sm="3">Тип</v-col>
                        <v-col sm="9">{{ siteType }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Стартовая страница</v-col>
                        <v-col sm="9">{{ startPage }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Название</v-col>
                        <v-col sm="9">{{ entity.title }}</v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Привязанный домен</v-col>
                        <v-col sm="9"><a :href="`https://${ entity.host }`" v-if="entity.host" target="_blank">https://{{ entity.host }}</a></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Адрес сайта доставки</v-col>
                        <v-col sm="9"><a :href="`${ siteLinkPreset }${ entity.code }`" target="_blank">{{ siteLinkPreset }}{{ entity.code }}</a></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Картинка</v-col>
                        <v-col sm="9"><v-img width="300px" v-if="entity.picture" :src="imageSrc(entity.picture)" /></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Описание</v-col>
                        <v-col sm="9" v-html="htmlFormatted(entity.description)"></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Сайт</v-col>
                        <v-col sm="9"><a :href="`${ entity.site }`" target="_blank">{{ entity.site }}</a></v-col>
                    </v-row>
                    <v-row form class="form-group">
                        <v-col sm="3">Активен</v-col>
                        <v-col sm="9">
                            <span :class="`true-false-label ${getBadge(entity.enabled)}`">
                                &nbsp;{{entity.enabled ? 'Да' : 'Нет' }}&nbsp;
                            </span>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pa-4 d-flex justify-space-between">
                    <div>
                        <v-btn
                            color="light"
                            class="mr-3"
                            @click="goBack"
                        >К списку</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import store from '../../store';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { mapState } from 'vuex';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';
    import SiteTabLinks from '../../components/sites/SiteTabLinks';

    import { imageSrc, htmlFormatted } from '../../helpers';

    export default {
        name: 'SiteDetail',

        components: {
            SiteTabLinks,
            yandexMap, 
            ymapMarker
        },

        data() {
            return {
                imageSrc,
                htmlFormatted,
                mapZoom: 12,
                deleteModal: false,
            };
        },

        computed: {
            ...mapState('auth', ['user']),
            ...mapState('sites', {
                entity: state => state.entity
            }),
            ...mapState('siteTypes', {
                siteTypes: state => state.entities
            }),
            siteLinkPreset() {
                return `${ process.env.VUE_APP_FRONT_URL }/`
            },
            isAdmin() {
                return this.user.admin;
            },
            siteType() {
                const type = this.siteTypes.find(item => item.id === this.entity.site_type_id);
                return type ? type.title : '';
            },
            startPage() {
                if(this.entity.start_page) {
                    return [
                        { code: 'site', title: 'Сайт' },
                        { code: 'delivery', title: 'Доставка' },
                        { code: 'menu', title: 'Электронное меню' }
                    ].find(item => item.code === this.entity.start_page).title;
                }
                return '';
            }
        },

        async mounted() {
            const id = this.$route.params.id;
            await store.dispatch('siteTypes/fetch');
            if(id) {
                await store.dispatch('sites/get', {id});
            }
        },

        methods: {
            getBadge (status) {
                return status ? 'success' : 'error';
            },
            goBack() {
                this.$router.push({ path: '/sites' });
            },
        },
    };
</script>

<style lang="scss">
    .entity_detail {
        &__map {
            width: 100%;
            height: 400px;

            &__wrapper {
                width: 100%;
                height: 400px;
                border-radius: 4px;
                overflow: hidden;
            }
        }
    }
</style>